import logo from "./assets/bmp.png";
import { useEffect, useState } from "react";
import { injected, getTotalSold, buyTokensPrivate } from "./utils/interact";
import { useWeb3React } from "@web3-react/core";

const Saler = () => {
   const [bnbAmount, setBNBAmount] = useState(0);
   const [tokenAmount, setTokenAmount] = useState(0);

   const { active, account, activate } = useWeb3React();

   useEffect(() => {
      async function run() {
         let amount = await getTotalSold();
         setTokenAmount(amount);
      }
      run();
   }, [account, active]);

   useEffect(() => {
      activate(injected);
   }, []);

   // const connectWalletPressed = async () => {
   //    activate(injected);
   // };

   const onBuyPressed = async () => {
      await buyTokensPrivate(bnbAmount, () => {});
   };

   return (
      <div className="Minter">
         {/* {!active && (
            <button type="button" id="walletButton" onClick={connectWalletPressed}>
               {account ? (
                  "Connected: " +
                  String(account).substring(0, 6) +
                  "..." +
                  String(account).substring(38)
               ) : (
                  <span>Connect Wallet</span>
               )}
            </button>
         )} */}

         <div className="clearboth"></div>
         <div className="alignCenter">
            <img className="imgLogo" src={logo} alt="BMP" />
         </div>
         <br />
         <div className="labelWrap">
            <span className="label">Total Raised</span>
            <span className="value">{(tokenAmount / 714).toFixed(2)} BNB</span>
         </div>
         <br />
         <div className="labelWrap">
            <span className="label">Total Token Sold</span>
            <span className="value">{parseFloat(tokenAmount).toFixed(2)} BMP</span>
         </div>
         <br></br>
         <form className="form">
            <h2>BNB value</h2>
            <input
               type="text"
               placeholder="0"
               value={bnbAmount}
               onChange={(event) => setBNBAmount(event.target.value)}
            />
         </form>
         <div className="alignCenter">
            <h1>BMP Amount: {(bnbAmount * 714).toFixed(2)}</h1>
            <p>(1 BMP = 0.0014005602240 BNB) </p>
            <button type="button" id="mintButton" onClick={onBuyPressed} disabled={bnbAmount <= 0}>
               BUY BMP Token
            </button>
         </div>
         <div className="pdf">
            <a href="https://docs.google.com/gview?embedded=true&url=https://bmptoken.com/presale.pdf">
               PreSale PDF
            </a>
            <a href="https://docs.google.com/gview?embedded=true&url=https://bmptoken.com/whitepaper.pdf">
               WhitePaper
            </a>
         </div>
      </div>
   );
};

export default Saler;
