import "./App.css";
import Saler from "./Saler";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider) {
   return new Web3Provider(provider);
}

function App() {
   return (
      <Web3ReactProvider getLibrary={getLibrary}>
         <div className="App">
            <Saler></Saler>
         </div>
      </Web3ReactProvider>
   );
}

export default App;
