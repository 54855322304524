import { InjectedConnector } from "@web3-react/injected-connector";
import { ethers } from "ethers";
import abi from "../presale.json";

export const contractAddress = "0x328240C37746a7bE4913463FDe746b6a9c4cF5CA";
export const injected = new InjectedConnector({});

export const getContract = () => {
   const provider = new ethers.providers.Web3Provider(window.ethereum);
   const signer = provider.getSigner();

   const contract = new ethers.Contract(contractAddress, abi, signer);

   return contract;
};

export const buyTokensPrivate = (amount, callback) => {
   const contract = getContract();
   contract.buyTokensPrivate({
      value: ethers.utils.parseEther(amount),
      gasLimit: 1000000,
   });
   contract.on("Sell", async () => {
      callback && callback();
   });
};

export const buyTokensPublic = (referrer, amount, callback) => {
   const contract = getContract();
   contract.buyEggs(referrer, {
      value: ethers.utils.parseEther(amount),
      gasLimit: 1000000,
   });
   contract.on("Sell", async () => {
      callback && callback();
   });
};

export const getTotalSold = async () => {
   const contract = getContract();
   const data = await contract.totalsold();
   return ethers.utils.formatEther(data);
};
